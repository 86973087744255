var moveSlider = function( slider, mode ) {
	var slides 		= $(slider).find('li');
	var hidden		= $(slider).find('li.hidden');
	
	var current;
	
	if( mode == 'next' ) {
	
		if( hidden.length > 0 ) {
			var last = hidden.get( hidden.length -1 );
			current = $(slides.get( slides.index(last) +1));
		} else {
			current = $(slides.get(0));
		}
		
		var id = slides.index(current);
		if(id != slides.length-1) {
			current.addClass('hidden');
		} else {
			slides.removeClass('hidden');
		}
	
	} else {
		
		if( hidden.length > 0 ) {
			$(hidden.get( hidden.length -1 )).removeClass('hidden');
		} else {
			slides.addClass('hidden');
			$(slides.get( slides.length -1 )).removeClass('hidden');
		}
	}
};

$(document).ready( function(){
	
	$('.slider').each(function(){
		var index = $(this).find('li').length;
		$(this).find('li').each( function(){
			$(this).css('z-index', index--);
		});
		
		$(this).parents('.wide-section-container').find('.wide-section-txt-holder').css('z-index', $(this).find('li').length +1);
	}); 
	
	$('.slider-controls a').click( function(){
		if( $(this).hasClass("previous") ) moveSlider( $(this).parents('.wide-section-img-holder').find('.slider'), 'previous' );
		if( $(this).hasClass("next") ) moveSlider( $(this).parents('.wide-section-img-holder').find('.slider'), 'next' );
	});
});