var initHeights = {};
$('.section-block-details').each(function(){
	var id = $(this).attr('id');
	initHeights[id] = $(this).prop('scrollHeight');
});

$(document).ready(function(){
		
	$('.switch a, .inner-nav a').click(function(){
		var url = $(this).data('url');
		$('.section-block').removeClass("shown");
		
		var block = $('#' + url );
		block.addClass('shown');
		
		var scrollHeight = initHeights[url];
		
		if( scrollHeight > 580 ) {
			block.height(scrollHeight + 120);
			block.parents('.tab').height(scrollHeight + 220);
			//block.height(scrollHeight + 100);
			//block.parent().height(scrollHeight + 200);		
		
		}
		
		$('.switch a').removeClass('active');
		$('.switch a[data-url="' + url + '"]').addClass('active');
	});
		
}); 