(function () {
	$('.hamburger-menu').on('click', function() {
		$('.bar').toggleClass('animate');
		$('.nav-holder').toggleClass('burger-open');
	});

	$('.nav-item').on('click', function() {
		$('.bar').removeClass('animate');
		$('.nav-holder').removeClass('burger-open');
	});
})();



var background_image_parallax = function($object, multiplier){
	
	multiplier = typeof multiplier !== 'undefined' ? multiplier : 0.5;
	multiplier = 1 - multiplier;
  	
  	var $doc = $(document);
  		
  	$object.css({"background-attatchment" : "fixed"});

		$(window).scroll(function(){

	  	var from_top = $doc.scrollTop(),
	    
	    bg_css = '0px ' + '-' + (multiplier * from_top) + 'px';
	  			
	  	$object.css({"background-position" : bg_css });

 	});
};

background_image_parallax($(".parallax"), 0.4);
background_image_parallax($(".parallax-slow"), 0.9);

(function($){
	$(function(){	
			var scroll = $(document).scrollTop();
			var headerHeight = 30;
			// console.log(headerHeight);
			
			$(window).scroll(function() {
				var scrolled = $(document).scrollTop();
							
			
				if (scrolled > headerHeight){
					$('.header-nav').addClass('off-canvas');
				} else {
					$('.header-nav').removeClass('off-canvas');
				}

			    if (scrolled > scroll){
					$('.header-nav').removeClass('fixed');
			    } else {
					$('.header-nav').addClass('fixed');
			    }				
				 
				scroll = $(document).scrollTop();	
			 });
 	});
})(jQuery);

// $('a[href*="#"]').click(function(e) {
//     e.preventDefault();
//     var target = this.hash;
//     $target = $(target);

//     $('html, body').stop().animate({
//         'scrollTop': $target.offset().top
//     }, 900, 'swing', function () {
//         window.location.hash = target;
//     });
// });


$(document).ready( function(){
  
  	$('.section-block a.more-toggle').click(function(){	 
	  	$('.section-block').removeClass('close'); 	
	  	$('.section-block').removeClass('active'); 	
	  	$(this).parents('.section-block').addClass('active');
	  	return false;
  	});
  	
  	$('a[data-goto]').click(function(evt){
	  	
	  	evt.preventDefault();
	  	
	  	var div = $(this).data('goto');
	  	$('html, body').animate({
          scrollTop: $('#' + div).offset().top
        }, 1000);
        
        var href = $(this).attr('href');
        
        history.pushState(null,null, href );
        ga("send","pageview", href);
        
	  	return false;
  	});

  	
  	$('.switch li a').click(function(){
	  	var href = $(this).attr('href');        
        history.pushState(null,null, href );
  	});
  	
  	$('.section-block a.close').click(function(){	  	
	  	$(this).parents('.section-block').removeClass('active').addClass('close');
	  	return false;
  	});
  
	$('a[data-url]').click( function(){
		var url = $(this).data('url');
		$('body').removeClass().addClass(url);
		return false;
	});	

	$('.contact-no-data').click( function(){
		$('body').addClass().addClass('contact-open');
		return false;
	});	
	
	$(window).scroll( function(){
		if( $(window).scrollTop() <= 0 ) {
			history.pushState(null,null, '/' + $('html').attr('lang') );
		}
	});
	
	$('.anchor').waypoint({
		handler: function(){
			var id = $(this.element).attr('id');
			if(id) {
				var href = $('a[data-goto="' + id + '"]').attr('href');
				history.pushState(null,null, href );
				ga("send","pageview", href);
			}
		}
	});
	
	var pathname = window.location.pathname;
	if( pathname.length > 0 ) {
		var a = $('a[href="' + pathname + '"]');
		$(a).trigger("click");
	}		
});




